import "./vendor-jquery"; // must be imported before jquery extensions

import "@benmajor/jquery-touch-events";
import autosize from "autosize";
import ClipboardJS from "clipboard";
import "jquery-serializejson";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Mustache from "mustache";
import Pikaday from "pikaday";
import "rangyinputs";
import "../../../../../../js/vendor/sticky-kit/sticky-kit.min";

window.autosize = autosize;
window.ClipboardJS = ClipboardJS;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.Mustache = Mustache;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.Pikaday = Pikaday;
